import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppState } from '../../index';
import { ClockLocationAction } from './clock-location.action';
import { ClockLocationApi } from './clock-location.api';
import { ClockLocationState } from './clock-location.model';

@Injectable()
export class ClockLocationService {
  public constructor(private store: Store<AppState>, private api: ClockLocationApi) {}

  public load() {
    this.store.dispatch(ClockLocationAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(ClockLocationAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ClockLocationAction.loadFailed(response));
        return observableThrowError(response);
      })
    );
  }

  public add(clockLocationData: { name: string; latitude: number; longitude: number; radius: number }) {
    this.store.dispatch(ClockLocationAction.add(clockLocationData));

    return this.api.add(clockLocationData).pipe(
      map((response) => {
        this.store.dispatch(ClockLocationAction.addSuccess(response));
        return response;
      }),
      catchError((error) => {
        this.store.dispatch(ClockLocationAction.addFailed(error));
        return observableThrowError(error);
      })
    );
  }

  public changeRadius(id: string, data: { radius: number }) {
    this.store.dispatch(ClockLocationAction.changeRadius(id, data.radius));

    return this.api.update(id, data).pipe(
      map((response) => {
        this.store.dispatch(ClockLocationAction.changeRadiusSuccess(response));
        return response;
      }),
      catchError((error) => {
        this.store.dispatch(ClockLocationAction.changeRadiusFailed(error));
        return observableThrowError(error);
      })
    );
  }

  public changeLocation(id, latitude, longitude) {
    this.store.dispatch(ClockLocationAction.changeLocation(id, latitude, longitude));

    return this.api.update(id, { latitude, longitude }).pipe(
      map((response) => {
        this.store.dispatch(ClockLocationAction.changeLocationSuccess(response));
        return response;
      }),
      catchError((error) => {
        this.store.dispatch(ClockLocationAction.changeLocationFailed(error));
        return observableThrowError(error);
      })
    );
  }

  public updateLocation(id, data) {
    this.store.dispatch(ClockLocationAction.updateLocation(id, data));

    return this.api.update(id, data).pipe(
      map((response) => {
        this.store.dispatch(ClockLocationAction.updateLocationSuccess(response));
        return response;
      }),
      catchError((error) => {
        this.store.dispatch(ClockLocationAction.updateLocationFailed(error));
        return observableThrowError(error);
      })
    );
  }

  public remove(id) {
    this.store.dispatch(ClockLocationAction.remove(id));

    return this.api.delete(id).pipe(
      map((response) => {
        this.store.dispatch(ClockLocationAction.removeSuccess(response));
        return response;
      }),
      catchError((error) => {
        this.store.dispatch(ClockLocationAction.removeFailed(error));
        return observableThrowError(error);
      })
    );
  }
}

export const getClockLocationState = (appState: AppState): ClockLocationState => appState.orm.clockLocations;

export const getClockLocations = createSelector(getClockLocationState, (state: ClockLocationState) =>
  Object.values(state.itemsById)
);
